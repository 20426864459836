'use client';

import { routes } from '@/routes';
import { useAppStore } from '@/stores/appStore';
import { isDevelopment } from '@wolfejs/core/utils/environment';
import type { UserSigninPayload } from '@wolfejs/ms-api-js/src/types/auth';

import { CookieExpiresMax, CookiesKeys } from '@/constants';
import { AppIds } from '@wolfejs/ms-api-js/src/constants/app-ids';
import { usePortalBySlug, useSignin } from '@wolfejs/ms-api-react/src';
import { ApiError } from '@wolfejs/ms-api-react/src/components/ApiError';
import { HeroLogin } from '@wolfejs/ms-ui/HeroLogin';
import { Loading } from '@wolfejs/ui/Loading';
import Cookies from 'js-cookie';
import { useParams, useRouter } from 'next/navigation';
import React from 'react';
import { localstore } from '../../../../utils/storage';

export const SigninContainer = () => {
  const router = useRouter();
  const signin = useSignin();
  const { portalSlug: slug } = useParams();

  const user = useAppStore(state => state.user);
  const setUser = useAppStore(state => state.setUser);

  const { data: portal, error: errorFetchingPortal, isLoading: isLoadingPortal } = usePortalBySlug(`${slug}`);

  React.useEffect(() => {
    if (user) {
      if (slug !== 'admin') {
        router.push(`/${slug}${routes.home}`);
      }
    }
  }, [router, user, slug]);

  const handleSubmit = async (signinPayload: UserSigninPayload) => {
    // store the id for this portal by slug in the cookies and localstorage
    if (localstore) localstore.set(`portal-${slug}-id`, portal?.id || '');
    Cookies.set(`portal-${slug}-id`, portal?.id || '', {
      expires: CookieExpiresMax,
    });

    signin.mutate(
      {
        email: signinPayload.email,
        password: signinPayload.password,
        appId: AppIds.PORTALS,
        appSubId: portal?.id,
      },
      {
        onSuccess: response => {
          setUser(response.data);

          const cookieOptions: Cookies.CookieAttributes = {
            path: '/',
          };

          if (!isDevelopment) {
            cookieOptions.sameSite = 'None';
            cookieOptions.secure = true;
          }

          Cookies.set(CookiesKeys.user, JSON.stringify(response.data), {
            expires: CookieExpiresMax,
          });

          // if (userBusinessMapping?.[0].businessId) {
          //   Cookies.set('user', userBusinessMapping?.[0].businessId);
          // }

          const tryRedirect = () => {
            // console.info('REDIRECTING TO:', `/${slug}${routes.signinRedirect}`);
            let redirectUrl =
              window.location.protocol + '//' + window.location.host + '/' + slug + '/' + routes.signinRedirect;

            // Redirect to merchant admin if needed
            if (response.data.isAdminMerchant) {
              console.info('PORTALS SIGNIN PAGE REDIRECTING TO MERCHANT ADMIN:', `/${slug}${routes.signinRedirect}`);
              redirectUrl = window.location.protocol + '//' + window.location.host + '/admin';
            } else {
              console.info('PORTALS SIGNIN PAGE REDIRECTING TO SIGNIN:', `/${slug}${routes.signinRedirect}`);
            }

            // redirect using the browser api
            window.location.href = redirectUrl;

            // router.push(`/${slug}${routes.signinRedirect}`);

            setTimeout(() => {
              // console.info('REFRESHING PAGE');
              // refresh the page if we still did not redirect after 3 seconds
              // router.refresh();

              // redirect using the browser api
              window.location.href = redirectUrl;
            }, 3000);
          };

          tryRedirect();
        },
      }
    );
  };

  if (errorFetchingPortal) return <ApiError error={errorFetchingPortal} />;
  if (isLoadingPortal) return <Loading />;

  return (
    <HeroLogin
      // Hardcoded title for now until we have admin dashboard
      title={'Insights into your Gift Card Business'}
      subTitle={portal?.subTitle ?? ''}
      onForgotPasswordClick={() => {
        router.push(`/${slug}${routes.forgotPassword}`);
      }}
      error={signin.error}
      onSubmit={handleSubmit}
      isLoading={signin.isLoading}
    />
  );
};
